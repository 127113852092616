import React, { useState, useEffect } from "react";
import CustomCard from "./CustomCard";
import "./FoodList.css";
import API from "../../utils/Api"; // Adjust the import path as necessary 

const FoodList = () => {
  const [foodItems, setFoodItems] = useState([]);

  const fetchFoodItems = async () => {
    try {
      const response = await fetch(`${API.localApi}/product`);
      const result = await response.json();

      console.log("Fetched Data:", result);

      if (Array.isArray(result.data)) {
        setFoodItems(result.data);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchFoodItems();
  }, []);

  return (
    <div className="food-list">
      {foodItems.length > 0 ? (foodItems.map((food, index) => (<CustomCard key={index} {...food} />))
      ) : (
        <p>Loading or no data available...</p>
      )}
    </div>
  );
};

export default FoodList;
