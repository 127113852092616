import React from "react";
import { Button } from "react-bootstrap";
import { FaThumbsUp } from "react-icons/fa";
import "./FoodList.css";
import API from "../../utils/Api";

const CustomCard = ({ dishName, restaurant, city, state, image, totalVotes, localVotes, description }) => {
  
  const handleVote = async () => {
    try {
      const response = await fetch(`${API.localApi}/product`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          dish_name: dishName,
          restaurant,
          state,
          city,
          latlng: [0, 0],  // Static values; replace with actual latlng if needed
          image,
          description,
          total_votes: totalVotes + 1,
          localVotes,
          link: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to vote");
      }

      alert(`Vote submitted for ${dishName}!`);
    } catch (error) {
      console.error("Error submitting vote:", error);
    }
  };

  return (
    <div className="food-card">
      <h2 className="food-title">{dishName}</h2>
      <h4 className="restaurant-name">{restaurant}</h4>
      <p className="city-state"><i>{city}, {state}</i></p>

      <div className="image-container">
        <img src={image} alt={dishName} className="food-img" />
      </div>

      <div className="custom-vote-section">
        Total Votes: <b>{totalVotes}</b><br />
        Local Votes: <b>{localVotes}</b>
      </div>

      <p className="food-description">
        {description} <a href="#"> Read more...</a>
      </p>

      <Button variant="success" className="vote-btn" onClick={handleVote}>
        <FaThumbsUp style={{ marginRight: "8px" }} /> Vote this dish
      </Button>
    </div>
  );
};

export default CustomCard;
