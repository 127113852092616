
import React, { useState} from "react";
import { FaUser, FaCity, FaBirthdayCake, FaVenusMars, FaPhone, FaEnvelope, FaTimes, FaPencilAlt } from "react-icons/fa";
import "./ProfileForm.css";
import API from "../../utils/Api"; 


const Profile = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    user_name: "",
    phone_number: "",
    // email: "",
    city_of_residence: "",
    DOB: "",
    gender: "",
    profile_picture: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!isOpen) return null;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, profile_picture: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API.localApi}/auth/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.statusCode === 201) {
        localStorage.setItem("authToken", data.data.token); 
        localStorage.setItem("userId", data.data.user.id);
        onClose();
      } else {
        setError(data.message || "Registration failed");
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    }

    setLoading(false);
  };


  return (
    <div className="profile-modal">
      <div className="profile-card">
        <div className="profile-header">
          <h2>My Profile</h2>
          <FaTimes className="close-icon" onClick={onClose} />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="profile-image-section">
            <label className="profile-upload">
              <img src={formData.profile_picture || "/assets/profile.png"} alt="Profile" className="profile-pic" />
              <input type="file" accept="image/*" onChange={handleImageUpload} hidden />
              {/* <FaPencilAlt className="edit-icon" /> */}
            </label>
          </div>

          <div className="profile-field">
            <label><FaUser /> First Name</label>
            <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />
          </div>

         

          <div className="profile-field">
            <label><FaPhone /> Phone Number</label>
            <input type="text" name="phone_number" value={formData.phone_number} onChange={handleChange} required />
          </div>

          {/* <div className="profile-field">
            <label><FaEnvelope /> Email</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </div> */}

          <div className="profile-field">
            <label><FaCity /> City</label>
            <input type="text" name="city_of_residence" value={formData.city_of_residence} onChange={handleChange} required />
          </div>

          <div className="profile-field">
            <label><FaBirthdayCake /> DOB</label>
            <input type="date" name="DOB" value={formData.DOB} onChange={handleChange} required />
          </div>

          <div className="profile-field">
            <label><FaVenusMars /> Gender</label>
            <select name="gender" value={formData.gender} onChange={handleChange} required>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {error && <p className="error-message">{error}</p>}

          <div className="profile-actions">
          <button type="button" className="cancel-btn" onClick={onClose}>Cancel</button>

            <button type="submit" className="save-btn" disabled={loading}>
              {loading ? "Saving..." : "Save Profile"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;


