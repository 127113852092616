import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./Mymap.css";
import FoodCard from "../../component/MapCard/MapCard";
import { useNavigate } from "react-router-dom";
import API from "../../utils/Api"; // Adjust the import path as necessary
const MyMap = () => {
  const [bestDishes, setBestDishes] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(7);
  const navigate = useNavigate(); 
  useEffect(() => {
    fetch(`${API.localApi}/product`)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data); // Check API response
        if (data.statusCode === 200 && Array.isArray(data.data)) {
          setBestDishes(data.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="map-container">
       <button className="back-button" onClick={() => navigate("/home")}>
        ← Back
      </button>
      <MapContainer center={[19.9975, 73.7898]} zoom={zoomLevel} className="leaflet-container">
        <UpdateZoom setZoomLevel={setZoomLevel} />

        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://carto.com/">CartoDB</a>'
        />
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://carto.com/">CartoDB</a>'
        />

        {bestDishes.map((place, index) => {
          const lat = parseFloat(place.latlng?.[0]); 
          const lng = parseFloat(place.latlng?.[1]);
          if (isNaN(lat) || isNaN(lng)) {
            return null;
          }

          return (
            <Marker
              key={index}
              position={[lat, lng]}
              icon={customIcon(place.image, zoomLevel * 5, place.dishName || place.dish_name)}
              eventHandlers={{ click: () => setSelectedLocation(place) }}
            >
              {/* <Popup>
                <b>{place.state}</b> <br />
                {place.city}
              </Popup> */}
            </Marker>
          );
        })}



      </MapContainer>

      {selectedLocation && (
        <div className="food-card-container">
          <FoodCard data={selectedLocation} onClose={() => setSelectedLocation(null)} />
        </div>
      )}
    </div>
  );
};

// Zoom Listener
const UpdateZoom = ({ setZoomLevel }) => {
  const map = useMap();
  useEffect(() => {
    const onZoomChange = () => {
      setZoomLevel(map.getZoom());
    };
    map.on("zoomend", onZoomChange);
    return () => {
      map.off("zoomend", onZoomChange);
    };
  }, [map, setZoomLevel]);
  return null;
};

//  Icon Function
const customIcon = (imageUrl, size, dishName) =>
  L.divIcon({
    className: "custom-marker",
    html: `
      <div class="marker-icon" style="
        width: ${size}px;
        height: ${size}px;
        background-image: url(${imageUrl});
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        border: 3px solid white;
        box-shadow: 0 2px 10px rgba(0,0,0,0.3);
        position: relative;
      ">
        <div class="dish-label" style="
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
          background:orange;
          color: white;
          padding: 3px 6px;
          border-radius: 5px;
          font-size: 12px;
          white-space: nowrap;
        ">
          ${dishName}
        </div>
      </div>
    `,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, -size / 2],
  });


export default MyMap;
