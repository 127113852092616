import React, { useState, useEffect } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../../component/NavBar/Navbar";
import SignIn from "../SignInGoogle/SignInGoogle";
import Footer from "../../component/FooterWidget/Footer";
import VoteCard from "../../component/VoteCard/Votecard";
import StoryBox from "../../component/WhyDoWeExist/WhyDoWeExist";
import StoryBoxs from "../../component/HowDoWeRank/HowDoWeRank";
import Profile from "../ProfileForm/ProfileForm";
import API from "../../utils/Api";


const Home = () => {
  const navigate = useNavigate();
  const [isLoginOpen, setIsLoginOpen] = useState(false); 
  const [isProfileOpen, setIsProfileOpen] = useState(false); 
  const [user, setUser] = useState(null); 
  const userId = localStorage.getItem("userId"); 


  useEffect(() => {
      fetchUserData();
  }, [userId]);
  const fetchUserData = async () => {
    try {
      const response = await fetch(`${API.localApi}/auth/user/${userId}`);
      const data = await response.json();
      if (data.statusCode === 200) {
        setUser(data.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
console.log("user", user);

  return (
    <div>
      
    
    <div className="home-container">
      {/* Header Section */}
      <div className="header-row">
        <img src="/assets/logo.png" alt="App Logo" className="logo" height={150} width={150} />
        <br />
        <div className="login-section">
          {user ? (
            <div className="user-info" onClick={() => setIsProfileOpen(true)}>
              <img 
                src={user.profile_picture || "/assets/profile.png"} 
                alt="User Profile" 
                className="user-avatar"
                 
              />
              <span className="user-name">{user.first_name}</span>
            </div>
          ) : (
            <a onClick={() => setIsProfileOpen(true)} className="login-text">Log in</a>
          )}
        </div>
      </div>

      {/* Navbar */}
      <NavbarComponent />

      {/* Background Section */}
      <div className="background-section">
        <h5 className="heading-text">Taste The Local Best...!</h5>
        <button className="explore-btn" onClick={() => navigate("/map")}>
          <i>Tap To Explore Map</i>
        </button>
      </div>

      <br /><br />

      {/* Login Modal */}
      {isLoginOpen && (
        <div className="modal-overlay" onClick={() => setIsLoginOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <SignIn onClose={() => setIsLoginOpen(false)} />
          </div>
        </div>
      )}

      {/* Components Section */}
      <VoteCard />
      <br />
      <StoryBox />
      <br />
      <StoryBoxs />
      <br />

      {/* Profile Modal */}
      {isProfileOpen && (
        <div className="modal-overlay" onClick={() => setIsProfileOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <Profile isOpen={isProfileOpen} onClose={() => setIsProfileOpen(false)} />
          </div>
        </div>
      )}

      <br />
      {/* <UserSignUp  /> */}


    </div>
    <Footer />

    </div>
  );
};

export default Home;
