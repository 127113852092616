import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode}  from 'jwt-decode';

const SocialLogin = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const callApi = (user) => {
    const token = user.idToken || user.authToken;
    const provider = user.provider || 'google';

    const apiUrl = 'https://accounts.google.com/o/oauth2/auth';
    
    axios.post(apiUrl, null, { 
      params: {
        token: token,
        provider: provider
      }
    })
    .then(response => {
      console.log('API Response:', response);
    })
    .catch(error => {
      console.error('API Error:', error);
      alert('Failed to authenticate. Please try again.');
    });
  };

  const handleGoogleSuccess = (credentialResponse) => {
    console.log(credentialResponse);
    const userObject = jwtDecode(credentialResponse.credential);
    
    if (userObject?.name) {
      setName(userObject.name);
      
      // Add provider info to match original code's structure
      const userWithProvider = {
        ...userObject,
        idToken: credentialResponse.credential,
        provider: 'google'
      };
      
      // callApi(userWithProvider);
      navigate('/home', { state: { user: userWithProvider } });
    }
  };


  // Load Facebook SDK

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Login</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email"
              name="email" 
              placeholder="Enter your email"
              className="mt-1 block w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" 
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700" htmlFor="password">Password</label>
            <input 
              type="password"
              id="password"
              name="password"
              placeholder="Enter your password"
              className="mt-1 block w-full px-4 py-2 border rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" 
            />
          </div>
          <button 
            type="submit"
            className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Login
          </button>
        </form>
        <div className="mt-4 flex justify-center space-x-4">
          <div>
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={() => console.log('Login Failed')}
              useOneTap
              type="icon"
              shape="circle"
              size="large"
            />
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default SocialLogin;