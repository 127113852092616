import React from "react";
import "./FullStoryExist.css";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";


const WhyDoWeExist = () => {

  const navigate = useNavigate();

  const goHome = () => {
    navigate("/home")
  }
  const explore = () => {
    navigate("/home")
  };

  return (
    <div className="about-container">
      <div className="back-arrow" onClick={goHome}>
        <FaArrowLeft />
      </div>
      <div className="about-content">
        <h2>Why do we exist?</h2>
        <p>
          We are foodie, non-foodie and no-foodie-giving people. But we all like
          to eat good wherever we go. Taste is a memory, and when you visit any
          new city, you want to carry back some good experiences with you. I
          want to. And there are many like me, who also want to.
        </p>
        <p>
          And let’s say you and your friends visit a new city. You step out of
          your SUV as if <strong>Hangover</strong> (the movie ??) is being
          recreated. It’s sunny and cool outside. Stepping into a new
          environment makes you alert, you want to soak in this novelty. It is
          energizing you. Here you have the freedom to become any character,
          because nobody knows you here. And you are least bothered about
          anyone’s opinion.
        </p>
        <p>
          Taking advantage of this freedom, your gang just explored the city
          carefreely—the landmarks, the sights, the people, the culture—it's all
          mesmerizing. After walking so much, you feel hungry now. You want to
          <strong>TASTE THE LOCAL BEST.</strong> You ask someone there, you ask
          a local friend, you search on the internet, you look for it in some
          food reel and (v)blog. There’s so much research to do just for one
          question: <strong>“What to eat?”</strong> And the suggestions you’ll
          get won’t guarantee the best experience. I mean, I lived in Nashik and
          Pune and still could only suggest a couple of dishes to my visiting
          friends. You are already fatigued, and this long research is making
          you more agitated.
        </p>
        <p>
          And if you are an International visitor, oh god! You cannot afford to
          miss tasting the best of India. I mean, I would not like my guests to
          take a bad experience with them. And there are many people who want
          you to experience the best their city has to offer. Even you will do
          it, right? I will. But their votes are scattered across the internet
          and offline.
        </p>
        <p>
          How can we elect a Prime Minister if people’s opinions are scattered?
          We collect the consensus, clean it, sort it, analyze it, re-confirm
          it, and then declare the Prime Minister. For ranking the best taste of
          the city, we follow a similar stringent process. Collectively, we
          become ambassadors of our cities. <strong>To know more about the
            voting process, just click.</strong>
        </p>
        <p className="highlight">
          This map is <strong>BY you, me, and everyone</strong> and
          <strong> FOR you, me, and everyone.</strong> Mapped by people, not by
          money. Let’s make our cities welcoming.
        </p>
        <div className="button-container">
          <button className="explores-btn" onClick={explore}>
            Let's Explore
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhyDoWeExist;
