import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./App.css";
import Home from "./pages/home/Home";
import MyMap from "./pages/map/Mymap";
import "bootstrap/dist/css/bootstrap.min.css";
import FoodList from "./component/CustomCard/FoodList";
import ExploreDish from "./component/ExploreDish/ExploreDish";
import VoteForm from "./component/VoteForm/VoteForm";
import VotetheDish from "./component/VotetheDish/VoteForm";
import SplashScreen from "./pages/Splash/SplashScreen";
import WhyDoWeExist from "./component/WhyDoWeExist/FullStory/FullStoryExist";
import FullStoryRank from "./component/HowDoWeRank/FullStory/FullStoryRank";
import SocialLogin from './pages/SignInGoogle/SignInGoogle'; // Update this path to where your SocialLogin component is located

function App() {
  const GOOGLE_CLIENT_ID = "241124172608-lviq4e6nk1sotl20hdfft4ol83ju676g.apps.googleusercontent.com";
  
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={
            <>
              <SocialLogin />
              
            </>
          } />
          <Route path="/map" element={<MyMap />} />
          <Route path="/vote-form" element={<VoteForm />} />
          <Route path="/best-dishes" element={<FoodList />} />
          <Route path="/explore" element={<ExploreDish />} />
          <Route path="/vote-the-dish" element={<VotetheDish />} />
          <Route path="/why-do-we-exist" element={<WhyDoWeExist />} />
          <Route path="/how-do-we-rank" element={<FullStoryRank />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;