import React from 'react';
import { useLocation } from "react-router-dom";
import { Card, Button } from 'react-bootstrap';
import "./ExploreDish.css";
import FeedbackSection from '../FeedBack/Feedback';
import { FaMapMarkerAlt, FaCheckCircle } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ImageSlider = () => {
  const location = useLocation();
  const data = location.state?.data;

  const navigate = useNavigate();

  const goHome = () => {
    navigate("/home");
  }

  if (!data) return <p>Loading...</p>;

  return (
    <div className="container-fluid mt-4">
      <div className="back-arrow" onClick={goHome}>
                    <FaArrowLeft />
                    </div>
      <Card className="custom-card">
        <div className="image-container pt-3">
          <img src={data.image} className="carousel-img" />
          {/* <img src={data.image} alt={data.dish_name} className="carousel-img" /> */}
        </div>

        <Card.Body>
          <h3 className="dish-name">{data.dish_name}</h3>
          {/* <p className='hotel-description'><i>{data.description}</i></p> */}

          <div className='address'>
            <p className="hotel-name"><i><u>{data.restaurant}</u></i></p>
            <p className="location">{data.city}, {data.state}</p>
          </div>

          
            <Button className="get-direction-btn" onClick={() => window.open(data.link, "_blank")}>
              <FaMapMarkerAlt /> Get Direction
            </Button>
       

          <div className="vote-count-btn">
            Total Votes: {data.total_votes}
            <br />
            <span className="local-votes">Local Votes: {data.local_votes}</span>
          </div>


          <FeedbackSection />
        </Card.Body>
      </Card>
    </div>
  );
};

export default ImageSlider;
