import React from "react";
import "./FullStoryRank.css";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const FullStoryRank = () => {
  const navigate = useNavigate();
  

  const goHome = () => {
    navigate("/home");
  }

  const exploreRanking = () => {
    navigate("/home"); 
  };

  return (
    <div className="rank-container">
       <div className="back-arrow" onClick={goHome}>
              <FaArrowLeft />
              </div>
      <div className="rank-content">
        <h2>How Do We Rank?</h2>
        <p>
          Basically, you want to know what makes this map credible. As a user,
          you should know it all. Let me elaborate on the rationale for you.
        </p>

        <p>
          The credibility of this map depends on the genuineness of
          <strong> YOU</strong>, the voter. We thought, "Let’s make it harder
          to vote." Now, if you have to vote, you’ll have to log in using your
          mobile number. Unlike email IDs, a person has only one number in most
          cases. Thereby, we can minimize bogus email votes.
        </p>

        <p>
          Once you’ve created a profile on the platform, you can vote for only
          <strong> 5 DISHES</strong> (NOT RESTAURANTS) in each
          <strong> CITY</strong>. One dish is the minimum you can vote for, and
          5 is the max. You are allowed to vote for as many cities as you want,
          as long as they are in India. When you submit a vote, it will be
          displayed on your profile. To change your votes for a particular
          city, you’ll have to delete one of the 5 existing votes.
        </p>

        <p>
          After you submit a vote, the vote is stored in the database. It
          doesn’t reflect on the map right away. We look at the data, clean it,
          sort it, and then study it. Dishes with the most votes are
          highlighted, and we try to confirm whether the dish is really worthy
          of the rank by all means possible (talking to locals, studying blogs,
          and forums on the internet).
        </p>

        <p className="highlight">
          To be clear, though the voter mentions age and gender, we calculate
          generalised votes because it's <strong>DEMOCRACY</strong>. Mapped by
          people, not by money.
        </p>

        <div className="button-container">
          <button className="explores-btn" onClick={exploreRanking}>
            Explore Rankings
          </button>
        </div>
      </div>
    </div>
  );
};

export default FullStoryRank;
