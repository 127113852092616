import React, { useState } from "react";
import "./VoteCard.css";
import VoteForm from "../VoteForm/VoteForm";

const VoteCard = () => {

    const [isLoginOpen, setIsLoginOpen] = useState(false); 
    return (
        <div className="VoteCard-container">

            <div class="card-body card-body-vote">
                <p class="discription"> 
                    <i> Some people just want to do good for no reason.
                    <br />
                    This is an opportunity for them.</i></p>
                    <a onClick={() => setIsLoginOpen(true)} className="btn vote-btn">Vote the Best !</a>
                <h5 class="card-title  title"> Make Your City Welcoming.</h5>


              
            </div>

            {isLoginOpen && (
        <div className="modal-overlay" onClick={() => setIsLoginOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <VoteForm onClose={() => setIsLoginOpen(false)} /> {/* Pass close function */}
          </div>
        </div>
      )}

        </div>
    );
};

export default VoteCard;
